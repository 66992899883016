.page-header {
	font-size: $page-header-font-size;
	margin: 0 0 rem(15px);
	padding: 0;
	border: none;
	line-height: $page-header-line-height;
	font-weight: $page-header-font-weight;
	
	& small {
		line-height: rem(20px);
	}
}

.ph-no-margin{
	margin: 0 !important;
	padding-top: rem(15px);
}

.page-header-fixed {
	background-color: #171717;
	top: $app-header-height;
	padding: 0.5% 5% 0.5% 0;
	z-index: 100; 
	width: calc(100% - $app-sidebar-width);
}

.app-sidebar-minified:not(.app-without-sidebar) {
	& .page-header-fixed {
		width: calc(100% - $app-sidebar-minified-width);
	}
}