.form-control,
.form-select {
	border-color: var(--app-component-border-color);
}
.form-select:not([multiple]) {
	& option {
		color: var(--app-component-color);
	}
}
.form-control {
	min-height: auto!important;
}