.app-content > .news-feed {
	max-width: 100vw;
	height: calc(100vh - $app-header-height);
	overflow: hidden;
	transform: translateZ(0);
	margin: (-$app-content-padding-y) (-$app-content-padding-x);

	
	@if $enable-rtl {
		right: 0;
		left: rem(500px);
	}
	@include media-breakpoint-down(lg) {
		right: $login-register-news-feed-container-width-sm;
		
		@if $enable-rtl {
			right: 0;
			left: $login-register-news-feed-container-width-sm;
		}	
	}
	@include media-breakpoint-down(md) {
		height: calc(100vh - calc($app-header-height*2) - 1px);
		width: 100vw;
		margin: (-$app-content-padding-y-sm) (-$app-content-padding-x-sm);
	}
	& .news-image {
		position: absolute;
		bottom: 0;
		left: 0;
		right: 0;
		top: 0;
		background-size: cover;
		background-repeat: no-repeat;
		background-position: center;
	}
	& .news-caption {
		color: rgba($white, .85);
		position: absolute;
		bottom: 0;
		left: 0;
		right: 0;
		padding: rem(60px) rem(60px) rem(20px);
		font-size: rem(18px);
		letter-spacing: rem(0.25px);
		z-index: 20;
		font-weight: 300;
		background: linear-gradient(to bottom, (rgba($black, 0) 0%, rgba($black, 0.6) 35%, rgba($black, 1) 70%));
		
		@include media-breakpoint-down(lg) {
			padding: rem(45px) rem(45px) rem(15px);
			font-size: rem(16px);
		}
		
		& .caption-title {
			font-weight: 300;
			color: $white;
			font-size: rem(36px);
		
			@include media-breakpoint-down(lg) {
				font-size: rem(28px);
			}
		}
	}
}


.login.login-with-news-feed,
.register.register-with-news-feed {
	min-height: 100vh;
	width: 100vw;
	display: flex;
	flex-flow : row nowrap;
	align-items: stretch;

	& .news-feed {
		width: 60vw;
		height: 100vh;
		overflow: hidden;
		transform: translateZ(0);
		
		@if $enable-rtl {
			right: 0;
			left: rem(500px);
		}
		@include media-breakpoint-down(lg) {
			right: $login-register-news-feed-container-width-sm;
			
			@if $enable-rtl {
				right: 0;
				left: $login-register-news-feed-container-width-sm;
			}	
		}
		@include media-breakpoint-down(md) {
			display: none;
		}
		& .news-image {
			position: absolute;
			bottom: 0;
			left: 0;
			right: 0;
			top: 0;
			background-size: cover;
			background-repeat: no-repeat;
			background-position: center;
		}
		& .news-caption {
			color: rgba($white, .85);
			position: absolute;
			bottom: 0;
			left: 0;
			right: 0;
			padding: rem(60px) rem(60px) rem(20px);
			font-size: rem(18px);
			letter-spacing: rem(0.25px);
			z-index: 20;
			font-weight: 300;
			background: linear-gradient(to bottom, (rgba($black, 0) 0%, rgba($black, 0.6) 35%, rgba($black, 1) 70%));
			
			@include media-breakpoint-down(lg) {
				padding: rem(45px) rem(45px) rem(15px);
				font-size: rem(16px);
			}
			
			& .caption-title {
				font-weight: 300;
				color: $white;
				font-size: rem(36px);
			
				@include media-breakpoint-down(lg) {
					font-size: rem(28px);
				}
			}
		}
	}

	& .login-container,
	& .register-container {
		width: 40vw;
		background: var(--app-component-bg);
		padding: rem(60px);
		min-height: 100vh;
		display: flex;
		flex-direction: column;
		justify-content: center;
		
		@if $enable-rtl {
			margin-right: auto;
		} @else {
			margin-left: auto;
		}
		@include media-breakpoint-down(lg) {
			padding: rem(45px);
			width: $login-register-news-feed-container-width-sm;
		}
		@include media-breakpoint-down(md) {
			width: auto;
			padding: rem(30px);
		}
	}
	& .login-header {
		position: relative;
		display: flex;
		align-items: center;
	
		& .navbar-language {
			& .flag-icon {
				font-size: rem(15px);
				
				@if $enable-rtl {
					margin-left: rem(7px);
				} @else {
					margin-right: rem(7px);
				}
			}
		}

		& .brand {
			padding: 0;
			font-size: rem(28px);
			color: var(--app-component-color);
			font-weight: 300;
			flex: 1;
			
			@include media-breakpoint-down(md) {
				font-size: rem(22px);
			}
	
			& .logo {
				border: 14px solid transparent;
				border-color: transparent rgba($black, .15) rgba($black, .3);
				background-color: var(--app-theme);
				width: rem(28px);
				height: rem(28px);
				font-size: 0;
				border-radius: $border-radius-lg;
		
				@if $enable-rtl {
					margin-left: rem(10px);
				} @else {
					margin-right: rem(10px);
				}
				@include media-breakpoint-down(md) {
					width: rem(24px);
					height: rem(24px);
					border-width: 12px;
				}
			}
			& small {
				font-size: rem(14px);
				display: block;
				color: rgba(var(--app-component-color-rgb), .5);
				font-weight: $font-weight-normal;
			
				@include media-breakpoint-down(md) {
					font-size: rem(13px);
				}
			}
		}
		
		& .navbar-item {
			position: relative;
			
			& .navbar-link {
				display: block;
				text-decoration: none;
				line-height: rem(20px);
				padding: rem(15px);
				border: none;
				color: var(--app-header-color);
				
				& .badge {
					position: absolute;
					top: rem(8px);
					display: block;
					background: var(--app-theme);
					line-height: rem(12px);
					font-weight: $font-weight-bold;
					color: var(--app-theme-color);
					padding: rem(3px) rem(6px);
					font-size: rem(14px) * 0.75;
					border-radius: $border-radius-lg * 5;
					
					@if $enable-rtl {
						left: rem(5px);
					} @else {
						right: rem(5px);
					}
				}
				& i {
					position: relative;
					font-size: rem(16px);
				}
				&:hover,
				&:focus {
					opacity: .75;
				}
				&.icon {
					& i {
						font-size: rem(16px);
						height: rem(20px);
						margin: 0;
						display: flex;
						align-items: center;
						justify-content: center;
					}
				}
			}
			
			&:last-child {
				& .navbar-link {
					@include media-breakpoint-down(md) {
						padding-right: 0;
					
						@if $enable-rtl {
							padding-right: 20px;
							padding-left: 0;
						}
					}
				}
			}
			&.active {
				& > a {
					background: var(--app-header-hover-bg);
					opacity: 1;
				}
			}
			&.dropdown {
				& .dropdown-menu {
					margin: 0;
					top: -2px !important;
				}
				
				&.dropdown-lg {
					position: static;
					
					& .dropdown-menu {
						left: rem(20px) !important;
						right: rem(20px) !important;
						padding: rem(20px);
						transform: none !important;
						
						@include media-breakpoint-up(md) {
							top: 100% !important;
						}
						@include media-breakpoint-down(sm) {
							overflow: scroll;
							top: $app-header-height-sm * 2 + rem(1px) !important;
							max-height: calc(100vh - #{$app-header-height-sm * 2 + rem(1px)});
						}
						
						&.dropdown-menu-lg {
							& .nav {
								& > li {
									& > a {
										padding: 0;
										background: none;
										line-height: rem(24px);
									}
								}
							}
						}
					}
				}
			}
		}
	}
}